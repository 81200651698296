import React, { useState } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';



import FGL_01_edit from '../../img/fotos/FGL_01_edit.jpg'
import FGL_02_edit from '../../img/fotos/FGL_02_edit.jpg'
import FGL_03_edit from '../../img/fotos/FGL_03_edit.jpg'
import FGL_04_edit from '../../img/fotos/FGL_04_edit.jpg'
import FGL_05_edit from '../../img/fotos/FGL_05_edit.jpg'
import FGL_06_edit from '../../img/fotos/FGL_06_edit.jpg'
import FGL_07_edit from '../../img/fotos/FGL_07_edit.jpg'
import FGL_08_edit from '../../img/fotos/FGL_08_edit.jpg'
import FGL_09_edit from '../../img/fotos/FGL_09_edit.jpg'
import FGL_10_edit from '../../img/fotos/FGL_10_edit.jpg'




import Juncal from '../../img/juncal-nor-oriente.jpg'
import Glaciofoto from '../../img/glaciologia_01.jpg'
import Glaciofoto2 from '../../img/glaciologia_02.jpg'
import Erupcion from '../../img/erupcion.png'
import TestigoPedazo from '../../img/testigos_pedazo.png'
import Derretimiento from '../../img/derretimiento01.jpg'
import Derretimiento2 from '../../img/derretimiento02.jpg'







const sliderImages = [
  FGL_01_edit,
  FGL_02_edit,
  FGL_03_edit,
  FGL_04_edit,
  FGL_05_edit,
  FGL_06_edit,
  FGL_07_edit,
  FGL_08_edit,
  FGL_09_edit,
  FGL_10_edit,
    Juncal,
  Glaciofoto ,
  Glaciofoto2 ,
  Erupcion,
  TestigoPedazo,
  Derretimiento,
  Derretimiento2,
];

const Fotos = () => {

  const [selected, setSelected] = useState(0);

  const handleTransitionRequest = (currentIndex, nextIndex) => {
    setSelected(nextIndex);
  };

  return (

    <div className="wrapperSection wrapper-2">
  
   <h2 className='sticky-text top-black'>Fotos</h2>
   <div className="slider-container">
      <AwesomeSlider
        animation="slide"
    
        play={true}
        infinite={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={6000}
        buttons={true}
        bullets={false}
        organicArrows={true}
        selected={selected}
        onTransitionRequest={handleTransitionRequest}
        className="slider"
        showThumbnails 
        mobileTouch={true}
      >
        {sliderImages.map((image, index) => (
          <div key={index} data-src={image} />
        ))}

      </AwesomeSlider>
      <div className="slider-controls">
        {sliderImages.map((_, index) => (
          <button
            key={index}
            className={`slider-control ${
              selected === index ? 'active' : ''
            }`}
            onClick={() => setSelected(index)}
          >
            {index + 1}
          </button>
        ))}

      </div>
    </div>

    </div>
  );
};

export default Fotos;

