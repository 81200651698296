import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

import Expo02 from '../../img/expo/expo01.jpg'
import Expo01 from '../../img/expo/expo02.jpg'
import Expo03 from '../../img/expo/expo03.jpg'
import Expo04 from '../../img/expo/expo04.jpg'

const sliderImages = [
  Expo01,
  Expo02,
  Expo03,
  Expo04,
];

class Expo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
    };
  }

  handleTransitionRequest = (currentIndex, nextIndex) => {
    this.setState({selected: nextIndex});
  };

  render() {
    return (
      <div className="wrapperSection wrapper-2">
        <h2 className='sticky-text top-black'>Expo</h2>
        <div className="slider-container">
          <AwesomeSlider
            animation="slide"
            play={true}
            infinite={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={6000}
            buttons={true}
            bullets={false}
            organicArrows={true}
            selected={this.state.selected}
            onTransitionRequest={this.handleTransitionRequest}
            className="slider"
            showThumbnails 
            mobileTouch={true}
          >
            {sliderImages.map((image, index) => (
              <div key={index} data-src={image} />
            ))}

          </AwesomeSlider>
          <div className="slider-controls">
            {sliderImages.map((_, index) => (
              <button
                key={index}
                className={`slider-control ${ this.state.selected === index ? 'active' : ''}`}
                onClick={() => this.setState({selected: index})}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Expo;