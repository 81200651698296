import React from 'react';
import ReactPlayer from 'react-player'
import Video from '../../video/Expedicion-Juncal.mp4'


class Videos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      rootURL: '/Videos',
      menuVisible: false,
      endNavVisible: true,
    };
  }

  render() {
    return (
      <div className="wrapperSection video">
     <ReactPlayer
  url={Video}
  className='react-player'
  width='100%'
  height='100%'
  playing={true}
/>
      </div>
    );
  }
}


export default Videos;