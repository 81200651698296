import React from 'react';
import './Timeline.scss';

import TinyTriangle from "../../img/triangle.svg"
import TimelineArrow from "../../img/timeline-arrow.svg"

const TimelineValpo = () => {
	// Define timeline data
	const timelineData = [
		{"year": 1750, "color": "#B3DDE2"},
		{"year": 1751, "color": "#E5BD89"},
		{"year": 1752, "color": "#E5BD89"},
		{"year": 1753, "color": "#FFFFFF"},
		{"year": 1754, "color": "#BCE1E9"},
		{"year": 1755, "color": "#B3DDE2"},
		{"year": 1756, "color": "#E5BD89"},
		{"year": 1757, "color": "#D0AC7F"},
		{"year": 1758, "color": "#8A4614"},
		{"year": 1759, "color": "#FFFFFF"},
		{"year": 1760, "color": "#BCE1E9"},
		{"year": 1761, "color": "#BCE1E9"},
		{"year": 1762, "color": "#BCE1E9"},
		{"year": 1763, "color": "#B3DDE2"},
		{"year": 1764, "color": "#B3DDE2"},
		{"year": 1765, "color": "#BCE1E9"},
		{"year": 1766, "color": "#FFFFFF"},
		{"year": 1767, "color": "#FACF98"},
		{"year": 1768, "color": "#BCE1E9"},
		{"year": 1769, "color": "#BCE1E9"},
		{"year": 1770, "color": "#E5BD89"},
		{"year": 1771, "color": "#FACF98"},
		{"year": 1772, "color": "#E5BD89"},
		{"year": 1773, "color": "#E5BD89"},
		{"year": 1774, "color": "#FACF98"},
		{"year": 1775, "color": "#FFFFFF"},
		{"year": 1776, "color": "#BCE1E9"},
		{"year": 1777, "color": "#BCE1E9"},
		{"year": 1778, "color": "#FFFFFF"},
		{"year": 1779, "color": "#BCE1E9"},
		{"year": 1780, "color": "#8A4614"},
		{"year": 1781, "color": "#FACF98"},
		{"year": 1782, "color": "#FACF98"},
		{"year": 1783, "color": "#FACF98"},
		{"year": 1784, "color": "#BCE1E9"},
		{"year": 1785, "color": "#FFFFFF"},
		{"year": 1786, "color": "#FFFFFF",tooltip:"Construcción hospital San Juan de Dios de Valparaíso, Chile"},
		{"year": 1787, "color": "#FACF98"},
		{"year": 1788, "color": "#FFFFFF"},
		{"year": 1789, "color": "#FACF98"},
		{"year": 1790, "color": "#9CD3D4"},
		{"year": 1791, "color": "#BCE1E9"},
		{"year": 1792, "color": "#BCE1E9"},
		{"year": 1793, "color": "#03688B"},
		{"year": 1794, "color": "#9CD3D4"},
		{"year": 1795, "color": "#FFFFFF"},
		{"year": 1796, "color": "#FFFFFF"},
		{"year": 1797, "color": "#FACF98"},
		{"year": 1798, "color": "#FACF98"},
		{"year": 1799, "color": "#9CD3D4"},
		{"year": 1800, "color": "#FFFFFF"},
		{"year": 1801, "color": "#BCE1E9"},
		{"year": 1802, "color": "#BCE1E9"},
		{"year": 1803, "color": "#FACF98"},
		{"year": 1804, "color": "#E5BD89"},
		{"year": 1805, "color": "#D0AC7F"},
		{"year": 1806, "color": "#FACF98"},
		{"year": 1807, "color": "#FFFFFF"},
		{"year": 1808, "color": "#9CD3D4"},
		{"year": 1809, "color": "#B3DDE2"},
		{"year": 1810, "color": "#FACF98"},
		{"year": 1811, "color": "#E5BD89"},
		{"year": 1812, "color": "#FACF98"},
		{"year": 1813, "color": "#BCE1E9"},
		{"year": 1814, "color": "#BCE1E9"},
		{"year": 1815, "color": "#E5BD89"},
		{"year": 1816, "color": "#D0AC7F"},
		{"year": 1817, "color": "#D0AC7F",down: true,tooltip: "Cruce de los Andes del General San Martín junto al Ejército Libertador, Cordillera de Los Andes"},
		{"year": 1818, "color": "#D0AC7F",tooltip:"Declaración de la independencia de Chile"},
		{"year": 1819, "color": "#BCE1E9"},
		{"year": 1820, "color": "#E5BD89"},
		{"year": 1821, "color": "#FFFFFF"},
		{"year": 1822, "color": "#FFFFFF"},
		{"year": 1823, "color": "#FACF98",down: true,tooltip:"Abolición de la esclavitud en Chile"},
		{"year": 1824, "color": "#E5BD89"},
		{"year": 1825, "color": "#9CD3D4"},
		{"year": 1826, "color": "#BCE1E9"},
		{"year": 1827, "color": "#BCE1E9",tooltip:"Fundación de El Mercurio de Valparaíso, Chile"},
		{"year": 1828, "color": "#B3DDE2"},
		{"year": 1829, "color": "#E5BD89"},
		{"year": 1830, "color": "#BCE1E9"},
		{"year": 1831, "color": "#B3DDE2"},
		{"year": 1832, "color": "#FFFFFF", down: true, tooltip:"Charles Darwin recorre Chile, desde Tierra del Fuego a Copiapó"},
		{"year": 1833, "color": "#BCE1E9"},
		{"year": 1834, "color": "#03688B"},
		{"year": 1835, "color": "#03688B"},
		{"year": 1836, "color": "#9CD3D4"},
		{"year": 1837, "color": "#FACF98"},
		{"year": 1838, "color": "#D0AC7F"},
		{"year": 1839, "color": "#FACF98"},
		{"year": 1840, "color": "#FFFFFF"},
		{"year": 1841, "color": "#9CD3D4"},
		{"year": 1842, "color": "#FFFFFF"},
		{"year": 1843, "color": "#9CD3D4"},
		{"year": 1844, "color": "#BCE1E9", down: true, tooltip:"España reconoce la Independencia de Chile"},
		{"year": 1845, "color": "#BCE1E9"},
		{"year": 1846, "color": "#BCE1E9"},
		{"year": 1847, "color": "#FFFFFF"},
		{"year": 1848, "color": "#B3DDE2"},
		{"year": 1849, "color": "#B3DDE2"},
		{"year": 1850, "color": "#FFFFFF"},
		{"year": 1851, "color": "#D0AC7F"},
		{"year": 1852, "color": "#FFFFFF"},
		{"year": 1853, "color": "#B3DDE2"},
		{"year": 1854, "color": "#B3DDE2"},
		{"year": 1855, "color": "#B3DDE2"},
		{"year": 1856, "color": "#B3DDE2"},
		{"year": 1857, "color": "#D0AC7F"},
		{"year": 1858, "color": "#FACF98"},
		{"year": 1859, "color": "#FFFFFF"},
		{"year": 1860, "color": "#9CD3D4"},
		{"year": 1861, "color": "#E5BD89"},
		{"year": 1862, "color": "#E5BD89"},
		{"year": 1863, "color": "#E5BD89"},
		{"year": 1864, "color": "#FFFFFF"},
		{"year": 1865, "color": "#B3DDE2"},
		{"year": 1866, "color": "#FACF98"},
		{"year": 1867, "color": "#BCE1E9"},
		{"year": 1868, "color": "#B3DDE2"},
		{"year": 1869, "color": "#E5BD89"},
		{"year": 1870, "color": "#E5BD89"},
		{"year": 1871, "color": "#B3DDE2"},
		{"year": 1872, "color": "#BCE1E9"},
		{"year": 1873, "color": "#BCE1E9",tooltip:"Se funda la Biblioteca Santiago Severín en Valparaíso, primera biblioteca pública del país."},
		{"year": 1874, "color": "#FFFFFF"},
		{"year": 1875, "color": "#FFFFFF"},
		{"year": 1876, "color": "#8A4614"},
		{"year": 1877, "color": "#FACF98"},
		{"year": 1878, "color": "#BCE1E9"},
		{"year": 1879, "color": "#FACF98"},
		{"year": 1880, "color": "#B3DDE2"},
		{"year": 1881, "color": "#03688B"},
		{"year": 1882, "color": "#9CD3D4"},
		{"year": 1883, "color": "#BCE1E9"},
		{"year": 1884, "color": "#BCE1E9"},
		{"year": 1885, "color": "#FACF98"},
		{"year": 1886, "color": "#FACF98"},
		{"year": 1887, "color": "#BCE1E9", down: true, tooltip:"Primeras profesionales universitarias de Sudamérica: las chilenas Eloísa Díaz y Ernestina Pérez, tituladas de médico."},
		{"year": 1888, "color": "#E5BD89"},
		{"year": 1889, "color": "#FFFFFF"},
		{"year": 1890, "color": "#FFFFFF"},
		{"year": 1891, "color": "#FACF98"},
		{"year": 1892, "color": "#FFFFFF"},
		{"year": 1893, "color": "#B3DDE2"},
		{"year": 1894, "color": "#9CD3D4"},
		{"year": 1895, "color": "#B3DDE2"},
		{"year": 1896, "color": "#FFFFFF"},
		{"year": 1897, "color": "#B3DDE2"},
		{"year": 1898, "color": "#E5BD89"},
		{"year": 1899, "color": "#9CD3D4"},
		{"year": 1900, "color": "#9CD3D4"},
		{"year": 1901, "color": "#BCE1E9"},
		{"year": 1902, "color": "#FACF98"},
		{"year": 1903, "color": "#E5BD89"},
		{"year": 1904, "color": "#E5BD89"},
		{"year": 1905, "color": "#E5BD89"},
		{"year": 1906, "color": "#BCE1E9"},
		{"year": 1907, "color": "#E5BD89"},
		{"year": 1908, "color": "#9CD3D4"},
		{"year": 1909, "color": "#BCE1E9"},
		{"year": 1910, "color": "#FACF98"},
		{"year": 1911, "color": "#E5BD89"},
		{"year": 1912, "color": "#FFFFFF"},
		{"year": 1913, "color": "#B3DDE2"},
		{"year": 1914, "color": "#E5BD89", tooltip:"Inundación en Valparaíso sector de Calle Condell, Chile"},
		{"year": 1915, "color": "#FACF98"},
		{"year": 1916, "color": "#BCE1E9"},
		{"year": 1917, "color": "#BCE1E9"},
		{"year": 1918, "color": "#D0AC7F"},
		{"year": 1919, "color": "#FFFFFF"},
		{"year": 1920, "color": "#FFFFFF"},
		{"year": 1921, "color": "#BCE1E9"},
		{"year": 1922, "color": "#BCE1E9"},
		{"year": 1923, "color": "#9CD3D4"},
		{"year": 1924, "color": "#9CD3D4"},
		{"year": 1925, "color": "#BCE1E9"},
		{"year": 1926, "color": "#FACF98"},
		{"year": 1927, "color": "#FACF98"},
		{"year": 1928, "color": "#E5BD89"},
		{"year": 1929, "color": "#D0AC7F"},
		{"year": 1930, "color": "#FACF98", down: true, tooltip:"Se crea la Fuerza Aérea Nacional"},
		{"year": 1931, "color": "#FACF98"},
		{"year": 1932, "color": "#BCE1E9"},
		{"year": 1933, "color": "#BCE1E9"},
		{"year": 1934, "color": "#B3DDE2"},
		{"year": 1935, "color": "#9CD3D4"},
		{"year": 1936, "color": "#FACF98"},
		{"year": 1937, "color": "#BCE1E9"},
		{"year": 1938, "color": "#E5BD89"},
		{"year": 1939, "color": "#8A4614"},
		{"year": 1940, "color": "#D0AC7F"},
		{"year": 1941, "color": "#BCE1E9"},
		{"year": 1942, "color": "#D0AC7F"},
		{"year": 1943, "color": "#BCE1E9"},
		{"year": 1944, "color": "#BCE1E9"},
		{"year": 1945, "color": "#FFFFFF"},
		{"year": 1946, "color": "#9CD3D4"},
		{"year": 1947, "color": "#E5BD89"},
		{"year": 1948, "color": "#FFFFFF"},
		{"year": 1949, "color": "#BCE1E9"},
		{"year": 1950, "color": "#E5BD89"},
		{"year": 1951, "color": "#E5BD89"},
		{"year": 1952, "color": "#FACF98"},
		{"year": 1953, "color": "#D0AC7F"},
		{"year": 1954, "color": "#FACF98"},
		{"year": 1955, "color": "#FFFFFF"},
		{"year": 1956, "color": "#9CD3D4"},
		{"year": 1957, "color": "#FFFFFF"},
		{"year": 1958, "color": "#BCE1E9"},
		{"year": 1959, "color": "#FFFFFF"},
		{"year": 1960, "color": "#E5BD89"},
		{"year": 1961, "color": "#8A4614"},
		{"year": 1962, "color": "#FFFFFF"},
		{"year": 1963, "color": "#FFFFFF"},
		{"year": 1964, "color": "#B3DDE2"},
		{"year": 1965, "color": "#FACF98"},
		{"year": 1966, "color": "#FACF98"},
		{"year": 1967, "color": "#BCE1E9"},
		{"year": 1968, "color": "#FACF98"},
		{"year": 1969, "color": "#FFFFFF"},
		{"year": 1970, "color": "#BCE1E9"},
		{"year": 1971, "color": "#FFFFFF"},
		{"year": 1972, "color": "#BCE1E9"},
		{"year": 1973, "color": "#E5BD89"},
		{"year": 1974, "color": "#B3DDE2"},
  {"year": 1975, "color": "#B3DDE2"},
  {"year": 1976, "color": "#BCE1E9"},
  {"year": 1977, "color": "#D0AC7F"},
  {"year": 1978, "color": "#FFFFFF"},
  {"year": 1979, "color": "#BCE1E9"},
  {"year": 1980, "color": "#E5BD89"},
  {"year": 1981, "color": "#E5BD89"},
  {"year": 1982, "color": "#E5BD89"},
  {"year": 1983, "color": "#FFFFFF"},
  {"year": 1984, "color": "#03688B"},
  {"year": 1985, "color": "#BCE1E9"},
  {"year": 1986, "color": "#FACF98",tooltip:"Incendio Placilla, Chile. Mas de 6 mil hectáreas afectadas"},
  {"year": 1987, "color": "#E5BD89"},
  {"year": 1988, "color": "#D0AC7F"},
  {"year": 1989, "color": "#FFFFFF"},
  {"year": 1990, "color": "#D0AC7F"},
  {"year": 1991, "color": "#BCE1E9"},
  {"year": 1992, "color": "#B3DDE2"},
  {"year": 1993, "color": "#BCE1E9"},
  {"year": 1994, "color": "#B3DDE2"},
  {"year": 1995, "color": "#FACF98"},
  {"year": 1996, "color": "#FFFFFF"},
  {"year": 1997, "color": "#BCE1E9"},
  {"year": 1998, "color": "#FFFFFF"},
  {"year": 1999, "color": "#FACF98"},
  {"year": 2000, "color": "#FFFFFF"},
  {"year": 2001, "color": "#FACF98"},
  {"year": 2002, "color": "#E5BD89"},
  {"year": 2003, "color": "#E5BD89"},
  {"year": 2004, "color": "#E5BD89"},
  {"year": 2005, "color": "#D0AC7F", down:true,tooltip:"Incendio Torres del Paine, Chile. 31 días de duración 15470 hectáreas afectadas"},
  {"year": 2006, "color": "#8A4614"},
  {"year": 2007, "color": "#BCE1E9"},
  {"year": 2008, "color": "#FACF98"},
  {"year": 2009, "color": "#FACF98"},
  {"year": 2010, "color": "#D0AC7F"},
  {"year": 2011, "color": "#8A4614",down:true,tooltip: "Incendio Torres del Paine (17 mil hectáreas)"},
  {"year": 2012, "color": "#D0AC7F"},
  {"year": 2013, "color": "#D0AC7F"},
  {"year": 2014, "color": "#D0AC7F",tooltip: "Incendio de Valparaíso, Chile. 12500 personas damnificadas"},
  {"year": 2015, "color": "#BCE1E9",down:true,tooltip:"Aluvión en regiones de Antofagasta y Atacama"},
  {"year": 2016, "color": "#E5BD89"},
  {"year": 2017, "color": "#FFFFFF",tooltip:"Gran incendio en la zona centro-sur de Chile, afectando más de tres regiones del país"},
  {"year": 2018, "color": "#FFFFFF"},
  {"year": 2019, "color": "#FACF98"},
  {"year": 2020, "color": "#E5BD89"},
  {"year": 2021, "color": "#9CD3D4", tooltip:"Aluvión Cajón del Maipo"},
  {"year": 2022, "color": "#BCE1E9", down:true, tooltip:"Incendio Forestal Alto, Viña del Mar, Chile."}
	];
	// Define timeline elements
	const timelineElements = timelineData.map((item) => (
	<div
	  key={item.year+""+Math.random()}
	  className="timeline-item"
	  title={item.tooltip}>
		
		<div className='tooltip-wrapper'> 

{(item.tooltip && !item.down)? <div className={(Number(item.year) > 1950)?"timeline-tooltip  reverse" : "timeline-tooltip"}>

	<img  alt='timeline-arrow' className='timeline-arrow' src={TimelineArrow}/>
	<div className='info-tooltip-up'>
			<h4>{item.year}</h4> 
			<p>{item.tooltip}</p>
		</div>
</div>: ""}

</div>
	<div className="timeline-color-block" 
  style={{ backgroundColor: item.color }}>
	</div>
	<div className='tooltip-wrapper down-wrapper'> 
	   <img className="timeline-triangle" src={TinyTriangle} alt="timeline-triangle"></img>
		<p className='timeline-year'>{item.year}</p>

		{(item.tooltip && item.down)? <div className={(Number(item.year) > 1950)?"timeline-tooltip down reverse" : "timeline-tooltip down"}>

			<img  alt='timeline-arrow' className='timeline-arrow' src={TimelineArrow}/>
			<div className='info-tooltip'>
					<h4 >{item.year}</h4> 
					<p>{item.tooltip}</p>
			</div>
		</div>: ""}
   </div> 
</div >
  ));


// Render timeline
return <div className="timeline-container">
	{timelineElements}
	</div>;
};

export default TimelineValpo;