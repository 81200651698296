import React from 'react';
import isoExpo from '../../img/isometrica_expo.svg'

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      rootURL: '/About',
      menuVisible: false,
      endNavVisible: true,
    };
  }

  render() {
    return (
      <div className="wrapperSection wrapper-2">
        	
          <h2 className="big-title anomalia top-2 sticky-text" alt="Anomalias de tempratura a escala global">Sobre el proyecto</h2>
          <img src={isoExpo}  className="isExpo" alt="Testigos de hielo" />
          <p  className='wideRead'>Testigos de Hielo es una instalación ubicada en el Parque Cultural de Valparaiso para la difusión del conocimiento científico, específicamente de la glaciología y el estudio de muestras de hielo obtenidas de glaciares de la región, para la comprensión del comportamiento climático en el último siglo. La propuesta fue desarrollada por un equipo multidisciplinar que a través del diseño arquitectónico, el diseño gráfico, la realización audiovisual y el montaje de espacios que hacen referencia a la disciplina, buscan promover la conciencia medioambiental a través de esta experiencia abierta y gratuita para el público.</p>>
      </div>
    );
  }
}

export default About;