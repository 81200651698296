import React from 'react';
import './Timeline.scss';

import TinyTriangle from "../../img/triangle.svg"
import TimelineArrow from "../../img/timeline-arrow.svg"

const TimelineCO2 = () => {
	// Define timeline data
	const timelineData = [

		{"year": 1750, "color": "#A5D9E9",tooltip:"Inicio de la revolución industrial"},
		{"year": 1751, "color": "#A5D9E9"},
		{"year": 1752, "color": "#A5D9E9"},
		{"year": 1753, "color": "#A5D9E9"},
		{"year": 1754, "color": "#A5D9E9"},
		{"year": 1755, "color": "#A5D9E9"},
		{"year": 1756, "color": "#A5D9E9"},
		{"year": 1757, "color": "#A5D9E9"},
		{"year": 1758, "color": "#A5D9E9"},
		{"year": 1759, "color": "#A5D9E9"},
		{"year": 1760, "color": "#A5D9E9"},
		{"year": 1761, "color": "#A5D9E9"},
		{"year": 1762, "color": "#A5D9E9"},
		{"year": 1763, "color": "#A5D9E9"},
		{"year": 1764, "color": "#98D4E1"},
		{"year": 1765, "color": "#98D4E1"},
		{"year": 1766, "color": "#98D4E1"},
		{"year": 1767, "color": "#98D4E1"},
		{"year": 1768, "color": "#98D4E1"},
		{"year": 1769, "color": "#98D4E1"},
		{"year": 1770, "color": "#98D4E1"},
		{"year": 1771, "color": "#98D4E1"},
		{"year": 1772, "color": "#98D4E1"},
		{"year": 1773, "color": "#98D4E1"},
		{"year": 1774, "color": "#98D4E1"},
		{"year": 1775, "color": "#98D4E1"},
		{"year": 1776, "color": "#98D4E1"},
		{"year": 1777, "color": "#98D4E1"},
		{"year": 1778, "color": "#98D4E1"},
		{"year": 1779, "color": "#98D4E1"},
		{"year": 1780, "color": "#98D4E1"},
		{"year": 1781, "color": "#98D4E1"},
		{"year": 1782, "color": "#98D4E1"},
		{"year": 1783, "color": "#98D4E1"},
		{"year": 1784, "color": "#98D4E1"},
		{"year": 1785, "color": "#98D4E1"},
		{"year": 1786, "color": "#98D4E1"},
		{"year": 1787, "color": "#98D4E1"},
		{"year": 1788, "color": "#98D4E1"},
		{"year": 1789, "color": "#A5D9E9"},
	{"year": 1790, "color": "#A5D9E9"},
	{"year": 1791, "color": "#A5D9E9"},
	{"year": 1792, "color": "#A5D9E9"},
	{"year": 1793, "color": "#A5D9E9"},
	{"year": 1794, "color": "#98D4E1"},
	{"year": 1795, "color": "#98D4E1"},
	{"year": 1796, "color": "#98D4E1"},
	{"year": 1797, "color": "#98D4E1"},
	{"year": 1798, "color": "#98D4E1"},
	{"year": 1799, "color": "#98D4E1"},
	{"year": 1800, "color": "#98D4E1"},
	{"year": 1801, "color": "#98D4E1"},
	{"year": 1802, "color": "#98D4E1"},
	{"year": 1803, "color": "#98D4E1"},
	{"year": 1804, "color": "#98D4E1"},
	{"year": 1805, "color": "#98D4E1"},
	{"year": 1806, "color": "#98D4E1"},
	{"year": 1807, "color": "#98D4E1"},
	{"year": 1808, "color": "#98D4E1"},
	{"year": 1809, "color": "#98D4E1"},
	{"year": 1810, "color": "#98D4E1"},
	{"year": 1811, "color": "#98D4E1"},
	{"year": 1812, "color": "#98D4E1"},
	{"year": 1813, "color": "#98D4E1"},
	{"year": 1814, "color": "#98D4E1"},
	{"year": 1815, "color": "#98D4E1", down:true, tooltip:"Erupción volcán Monte Tambora, Indonesia. La mayor erupción volcánica de la historia, las cenizas por varios años en la atmósfera y logró disminuir la temperatura media global más de 2.5°C"},
	{"year": 1816, "color": "#98D4E1"},
	{"year": 1817, "color": "#98D4E1"},
	{"year": 1818, "color": "#98D4E1"},
	{"year": 1819, "color": "#98D4E1"},
	{"year": 1820, "color": "#98D4E1"},
	{"year": 1821, "color": "#98D4E1"},
	{"year": 1822, "color": "#98D4E1"},
	{"year": 1823, "color": "#98D4E1"},
	{"year": 1824, "color": "#98D4E1"},
	{"year": 1825, "color": "#98D4E1"},
	{"year": 1826, "color": "#98D4E1"},
	{"year": 1827, "color": "#98D4E1"},
	{"year": 1828, "color": "#98D4E1"},
	{"year": 1829, "color": "#98D4E1"},
	{"year": 1830, "color": "#98D4E1"},
	{"year": 1831, "color": "#98D4E1"},
	{"year": 1832, "color": "#98D4E1"},
	{"year": 1833, "color": "#98D4E1",tooltip:"Erupción volcán Krakatoa, Indonesia. 33 mil persona fallecidas"},
	{"year": 1834, "color": "#98D4E1"},
	{"year": 1835, "color": "#98D4E1"},
	{"year": 1836, "color": "#98D4E1",down: true, tooltip:"Estalla la guerra contra la confederación Perú Boliviana"},
	{"year": 1837, "color": "#98D4E1"},
	{"year": 1838, "color": "#98D4E1"},
	{"year": 1839, "color": "#98D4E1"},
	{"year": 1840, "color": "#98D4E1",tooltip:"Fin de la revolución industrial"},
	{"year": 1841, "color": "#98D4E1"},
	{"year": 1842, "color": "#98D4E1"},
	{"year": 1843, "color": "#98D4E1"},
	{"year": 1844, "color": "#98D4E1"},
	{"year": 1845, "color": "#98D4E1"},
	{"year": 1846, "color": "#98D4E1"},
	{"year": 1847, "color": "#98D4E1"},
	{"year": 1848, "color": "#98D4E1"},
	{"year": 1849, "color": "#98D4E1"},
	{"year": 1850, "color": "#98D4E1"},
	{"year": 1851, "color": "#98D4E1"},
	{"year": 1852, "color": "#98D4E1"},
	{"year": 1853, "color": "#98D4E1"},
	{"year": 1854, "color": "#98D4E1"},
	{"year": 1855, "color": "#98D4E1"},
	{"year": 1856, "color": "#98D4E1"},
	{"year": 1857, "color": "#98D4E1"},
	{"year": 1858, "color": "#98D4E1"},
	{"year": 1859, "color": "#98D4E1"},
	{"year": 1860, "color": "#98D4E1"},
	{"year": 1861, "color": "#98D4E1"},
	{"year": 1862, "color": "#98D4E1"},
	{"year": 1863, "color": "#98D4E1"},
	{"year": 1864, "color": "#98D4E1"},
	{"year": 1865, "color": "#98D4E1"},
	{"year": 1866, "color": "#98D4E1"},
	{"year": 1867, "color": "#98D4E1"},
	{"year": 1868, "color": "#98D4E1"},
	{"year": 1869, "color": "#98D4E1"},
	{"year": 1870, "color": "#98D4E1"},
	{"year": 1871, "color": "#9ad5e2"},
	{"year": 1872, "color": "#9ad5e2"},
	{"year": 1873, "color": "#9ad5e2"},
	{"year": 1874, "color": "#84ccd6"},
	{"year": 1875, "color": "#9ad5e2"},
	{"year": 1876, "color": "#9ad5e2"},
	{"year": 1877, "color": "#9ad5e2"},
	{"year": 1878, "color": "#9ad5e2"},
	{"year": 1879, "color": "#9ad5e2",down: true, tooltip:"Inicia la Guerra del Pacífico"},
	{"year": 1880, "color": "#9ad5e2"},
	{"year": 1881, "color": "#9ad5e2"},
	{"year": 1882, "color": "#9ad5e2"},
	{"year": 1883, "color": "#84ccd6"},
	{"year": 1884, "color": "#9ad5e2"},
	{"year": 1885, "color": "#9ad5e2"},
	{"year": 1886, "color": "#9ad5e2"},
	{"year": 1887, "color": "#84ccd6"},
	{"year": 1888, "color": "#84ccd6"},
	{"year": 1889, "color": "#84ccd6"},
	{"year": 1890, "color": "#9ad5e2"},
	{"year": 1891, "color": "#84ccd6",tooltip:"La Guerra Civil de 1891 se extiende por 6 meses teniendo una de sus últimos episodios en las batallas de Concón y Placilla."},
	{"year": 1892, "color": "#84ccd6"},
	{"year": 1893, "color": "#84ccd6"},
	{"year": 1894, "color": "#84ccd6"},
	{"year": 1895, "color": "#84ccd6"},
	{"year": 1896, "color": "#84ccd6"},
	{"year": 1897, "color": "#84ccd6"},
	{"year": 1898, "color": "#84ccd6"},
	{"year": 1899, "color": "#84ccd6"},
	{"year": 1900, "color": "#84ccd6"},
	{"year": 1901, "color": "#84ccd6"},
	{"year": 1902, "color": "#84ccd6"},
	{"year": 1903, "color": "#84ccd6"},
	{"year": 1904, "color": "#84ccd6"},
	{"year": 1905, "color": "#84ccd6"},
	{"year": 1906, "color": "#84ccd6"},
	{"year": 1907, "color": "#84ccd6"},
	{"year": 1908, "color": "#84ccd6"},
	{"year": 1909, "color": "#84ccd6"},
	{"year": 1910, "color": "#84ccd6"},
	{"year": 1911, "color": "#84ccd6"},
	{"year": 1912, "color": "#84ccd6"},
	{"year": 1913, "color": "#84ccd6"},
	{"year": 1914, "color": "#84ccd6",tooltip:"Estalla la primera guerra mundial"},
	{"year": 1915, "color": "#84ccd6"},
	{"year": 1916, "color": "#84ccd6"},
	{"year": 1917, "color": "#c0c172"},
	{"year": 1918, "color": "#c0c172"},
	{"year": 1919, "color": "#c0c172",down:true, tooltip:"Fin a la primera guerra mundial"},
	{"year": 1920, "color": "#84ccd6"},
	{"year": 1921, "color": "#c0c172"},
	{"year": 1922, "color": "#c0c172"},
	{"year": 1923, "color": "#c0c172"},
	{"year": 1924, "color": "#c0c172"},
	{"year": 1925, "color": "#c0c172"},
	{"year": 1926, "color": "#c0c172"},
	{"year": 1927, "color": "#c0c172"},
	{"year": 1928, "color": "#c0c172"},
	{"year": 1929, "color": "#c0c172"},
	{"year": 1930, "color": "#c0c172"},
	{"year": 1931, "color": "#c0c172"},
	{"year": 1932, "color": "#c0c172",tooltip:"Erupción volcán Quizapu,caída de ceniza en Chile central, Argentina, Uruguay y Brasil."},
	{"year": 1933, "color": "#c0c172"},
	{"year": 1934, "color": "#c0c172"},
	{"year": 1935, "color": "#c0c172"},
	{"year": 1936, "color": "#c0c172"},
	{"year": 1937, "color": "#c0c172"},
	{"year": 1938, "color": "#c0c172"},
	{"year": 1939, "color": "#c0c172",down: true, tooltip:"Estalla la segunda guerra mundial"},
	{"year": 1940, "color": "#c0c172"},
	{"year": 1941, "color": "#c0c172"},
	{"year": 1942, "color": "#c0c172"},
	{"year": 1943, "color": "#c0c172"},
	{"year": 1944, "color": "#c0c172"},
	{"year": 1945, "color": "#c0c172",down: true, tooltip:"Fin a la segunda guerra mundial"},
	{"year": 1946, "color": "#c0c172"},
	{"year": 1947, "color": "#c0c172"},
	{"year": 1948, "color": "#c0c172",down: true, tooltip:"Erupción del Volcán Villarrica, Chile. La mayor erupción de este volcán en Chile hasta el momento"},
	{"year": 1949, "color": "#c0c172"},
	{"year": 1950, "color": "#c0c172"},
	{"year": 1951, "color": "#c0c172"},
	{"year": 1952, "color": "#c0c172"},
	{"year": 1953, "color": "#c0c172"},
	{"year": 1954, "color": "#c0c172"},
	{"year": 1955, "color": "#f8bb26"},
	{"year": 1956, "color": "#f8bb26"},
	{"year": 1957, "color": "#f8bb26"},
	{"year": 1958, "color": "#f8bb26"},
	{"year": 1959, "color": "#f8bb26"},
	{"year": 1960, "color": "#f8bb26"},
	{"year": 1961, "color": "#f8bb26"},
	{"year": 1962, "color": "#f8bb26",tooltip:"Relación de la contaminación de plomo con los combustibles fósiles: Clair Patterson y Tsaihwa Chow"},
	{"year": 1963, "color": "#f8bb26"},
	{"year": 1964, "color": "#f8bb26"},
	{"year": 1965, "color": "#f8bb26"},
	{"year": 1966, "color": "#f8bb26"},
	{"year": 1967, "color": "#f8bb26"},
	{"year": 1968, "color": "#f8bb26"},
	{"year": 1969, "color": "#e6af20",down: true, tooltip:"Llegada del humano a la luna, primeras mediciones satelitales de la atmósfera"},
	{"year": 1970, "color": "#e6af20"},
	{"year": 1971, "color": "#e6af20"},
	{"year": 1972, "color": "#e6af20"},
	{"year": 1973, "color": "#e6af20"},
	{"year": 1974, "color": "#e6af20"},
	{"year": 1975, "color": "#e6af20"},
	{"year": 1976, "color": "#e6af20"},
	{"year": 1977, "color": "#e6af20"},
	{"year": 1978, "color": "#d09822"},
	{"year": 1979, "color": "#d09822"},
	{"year": 1980, "color": "#d09822"},
	{"year": 1981, "color": "#d09822"},
	{"year": 1982, "color": "#d09822"},
	{"year": 1983, "color": "#d09822"},
	{"year": 1984, "color": "#d09822"},
	{"year": 1985, "color": "#ee7555"},
	{"year": 1986, "color": "#ee7555"},
	{"year": 1987, "color": "#ee7555"},
	{"year": 1988, "color": "#ee7555"},
	{"year": 1989, "color": "#ee7555"},
	{"year": 1990, "color": "#ee7555"},
	{"year": 1991, "color": "#ee7555",tooltip:"Erupción volcán Pinatubo, Filipinas.Se produjo una disminución de la temperatura media global de 0.5°C"},
	{"year": 1992, "color": "#ed6d53"},
	{"year": 1993, "color": "#ed6d53", down:true,tooltip:"Erupción volcán Láscar, Chile"},
	{"year": 1994, "color": "#ed6d53"},
	{"year": 1995, "color": "#ed6d53"},
	{"year": 1996, "color": "#ed6d53"},
	{"year": 1997, "color": "#ed6d53"},
	{"year": 1998, "color": "#da6049"},
	{"year": 1999, "color": "#da6049"},
	{"year": 2000, "color": "#da6049"},
	{"year": 2001, "color": "#da6049"},
	{"year": 2002, "color": "#da6049"},
	{"year": 2003, "color": "#da6049"},
	{"year": 2004, "color": "#9f6256"},
	{"year": 2005, "color": "#9f6256"},
	{"year": 2006, "color": "#9f6256"},
	{"year": 2007, "color": "#9f6256"},
	{"year": 2008, "color": "#9f6256",tooltip:"Erupción volcán Chaitén"},
	{"year": 2009, "color": "#9f6256"},
	{"year": 2010, "color": "#5b5a5a"},
	{"year": 2011, "color": "#5b5a5a", down: true,tooltip:"Erupción Puyehue-Cordón Caulle, Chile. Cenizas llegan hasta Argentina y hay interrupción del tráfico aéreo en el hemisferio sur."},
	{"year": 2012, "color": "#5b5a5a"},
	{"year": 2013, "color": "#5b5a5a"},
	{"year": 2014, "color": "#2e2f2f"},
	{"year": 2015, "color": "#2e2f2f",tooltip:"Erupción volcán Calbuco, Chile"},
	{"year": 2016, "color": "#2e2f2f"},
	{"year": 2017, "color": "#2e2f2f"},
	{"year": 2018, "color": "#2e2f2f", down: true,tooltip:"Alerta ambiental Quintero-Puchuncaví"},
	{"year": 2019, "color": "#020303"},
	{"year": 2020, "color": "#020303"},
	{"year": 2021, "color": "#020303"},
	{"year": 2022, "color": "#020303"}
		];
	// Define timeline elements
	const timelineElements = timelineData.map((item) => (
	 <div
		key={item.year+""+Math.random()}
		className="timeline-item"
		title={item.tooltip}>
		
		<div className='tooltip-wrapper'> 

{(item.tooltip && !item.down)? <div className={(Number(item.year) > 1950)?"timeline-tooltip  reverse" : "timeline-tooltip"}>

	<img  alt='timeline-arrow' className='timeline-arrow' src={TimelineArrow}/>
	<div className='info-tooltip-up'>
			<h4>{item.year}</h4> 
			<p>{item.tooltip}</p>
		</div>
</div>: ""}

</div>
	<div className="timeline-color-block" 
	style={{ backgroundColor: item.color }}>
	</div>
	<div className='tooltip-wrapper down-wrapper'> 
		 <img className="timeline-triangle" src={TinyTriangle} alt="timeline-triangle"></img>
			<p className='timeline-year'>{item.year}</p>

		{(item.tooltip && item.down)? <div className={(Number(item.year) > 1950)?"timeline-tooltip down reverse" : "timeline-tooltip down"}>

			<img  alt='timeline-arrow' className='timeline-arrow' src={TimelineArrow}/>
			<div className='info-tooltip'>
					<h4 >{item.year}</h4> 
					<p>{item.tooltip}</p>
				</div>
		</div>: ""}
	 </div> 
</div >
	));


// Render timeline
return <div className="timeline-container">
	{timelineElements}
	</div>;
};

export default TimelineCO2;